<template>
  <tp-modal name="modal-removing-accessories" max-width="768px">
    <v-card>
      <v-card-title>
        <div class="font-weight-bold">
          Chi tiết tháo linh kiện #{{ removeAccess.id }}
        </div>
        <v-spacer></v-spacer>
        <v-btn
          class="mr-1"
          color="red accent-1"
          icon
          @click="deleteRemoveAccess"
        >
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
        <v-btn icon @click="closeModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-container class="grey lighten-3 pa-5" fluid>
        <v-card flat>
          <v-row class="mx-0">
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Thực hiện bởi</div>
              <div class="py-1">{{ removeAccess.created_user_name }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Ngày thực hiện</div>
              <div class="py-1">{{ removeAccess.created_at }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Chỉnh sửa cuối</div>
              <div class="py-1">{{ removeAccess.created_at }}</div>
            </v-col>
            <v-col class="pa-5">
              <div class="font-weight-bold mb-1">Trạng thái</div>
              <div>
                <v-chip
                  class="text-body-2 font-weight-bold px-2"
                  :color="
                    removeAccess.status === 1
                      ? 'green'
                      : removeAccess.status === -1
                      ? 'red accent-2'
                      : 'orange'
                  "
                  outlined
                  small
                >
                  {{
                    removeAccess.status === 1
                      ? "Thành công"
                      : removeAccess.status === -1
                      ? "Bị huỷ"
                      : "Bị huỷ"
                  }}
                </v-chip>
              </div>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="mt-5" flat>
          <v-row class="mx-0">
            <col-serial-info
              :item="removeAccess.old"
              :title="'Máy gốc'"
            ></col-serial-info>
            <col-serial-info
              :item="removeAccess.new"
              :title="'Máy sau điều chỉnh'"
            ></col-serial-info>
          </v-row>
        </v-card>

        <v-card class="mt-5 pb-5" flat>
          <div class="font-weight-bold px-5 pt-4 mb-3">
            Linh kiện ({{ removeAccess.gears ? removeAccess.gears.length : 0 }})
          </div>
          <div class="px-5">
            <card-serial-info
              v-for="(gear, index) in removeAccess.gears"
              v-bind:key="index"
              class="mt-3"
              :item="gear"
            ></card-serial-info>
          </div>
        </v-card>
      </v-container>
    </v-card>
  </tp-modal>
</template>

<script>
import CardSerialInfo from "./components/CardSerialInfo";
import ColSerialInfo from "./components/ColSerialInfo";

export default {
  components: {
    CardSerialInfo,
    ColSerialInfo
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$modal.hide({ name: "modal-removing-accessories" });
    },
    deleteRemoveAccess() {
      this.$modal.show({
        name: "modal-alert",
        payload: {
          title: `<span>Xóa bản ghi này?</span>`,
          message:
            "Hành động này sẽ không thể hoàn tác. Bạn có chắc chắn muốn tiếp tục?",
          confirmBtn: {
            color: "red accent-2",
            text: "Xóa",
            onClickHandler: async () => {
              await this.$store.dispatch(
                "REMOVE_ACCESS/deleteRemoveAccess",
                this.removeAccess.id
              );
              if (
                this.removeAccessStatusRequest.value ===
                "success-deleteRemoveAccess"
              ) {
                this.$modal.hide({ name: "modal-removing-accessories" });
                // Alert
                this.$toast.show({
                  name: "toast-action-alert",
                  payload: {
                    message: "Đã xóa thành công"
                  }
                });
              }
            }
          }
        }
      });
    }
  },
  computed: {
    removeAccess() {
      return this.$store.getters["REMOVE_ACCESS/removeAccess"];
    },
    removeAccessStatusRequest() {
      return this.$store.getters["REMOVE_ACCESS/statusRequest"];
    }
  }
};
</script>

<style lang="scss" scoped>
.card-wrap {
  height: calc(100% - 44px);
}
</style>
